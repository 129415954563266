<template>
	<div class="main-content">
		<filter-modal :filter="filter" :show="is.showing.filterModal" @on-filter="onFilter" @close="closeFilterModal" />
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('Agri Input', 'Agri Input')"
			:title="$t('Agri Input Progress', 'Agri Input Progress')"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="premiumPointList"
			:search-enabled="false"
			:search-placeholder="$t('Search by disburse id', 'Search by disburse id')"
			:total-rows="totalRows"
			min-height="250px"
			mode="remote"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearch"
			@on-sort-change="onSortChange"
		>
			<template #actionright>
				<ktv-button class="ml-2" :text="$t('Filter', 'Filter')" icon="filter" color="light" @click="showFilterModal" />
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-item v-if="isAllowedToViewDetail" @click="showDetail(props.row.premiumDisburseId)">
							<img :src="icons.view" class="imgicon" />{{ $t("Detail", "Detail") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'premiumDisburseId'">{{ props.row.premiumDisburseId ? props.row.premiumDisburseId : "-" }}</span>
				<span v-else-if="props.column.field === 'siteName'">{{ props.row.siteName ? props.row.siteName : "-" }}</span>
				<span v-else-if="props.column.field === 'areaCode'">{{ props.row.areaCode ? props.row.areaCode : "-" }}</span>
				<span v-else-if="props.column.field === 'premiumStartDate'">{{ props.row.premiumStartDate ? props.row.premiumStartDate : "-" }}</span>
				<span v-else-if="props.column.field === 'premiumEndDate'">{{ props.row.premiumEndDate ? props.row.premiumEndDate : "-" }}</span>
				<span v-else-if="props.column.field === 'cvcAssociation'">{{ props.row.cvcAssociation ? props.row.cvcAssociation : "-" }}</span>
				<span v-else-if="props.column.field === 'totalPremiumAmount'">{{
					props.row.totalPremiumAmount >= 0 ? idrFilter(Math.round(props.row.totalPremiumAmount)) : "-"
				}}</span>
				<span v-else-if="props.column.field === 'statusDesc'">{{ props.row.statusDesc ? props.row.statusDesc : "-" }}</span>
				<span v-else-if="props.column.field === 'createdDate'">{{ props.row.createdDate ? props.row.createdDate : "-" }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each, findWhere } from "underscore"
	import { KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import FilterModal from "./_Filter"

	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		dropdown: dropdownIcon,
		view: viewIcon,
	}

	export default {
		name: "PremiumPointsAgriInputProgressList",
		metaInfo: {
			title: "Agri Input Progress",
		},
		components: { FilterModal, KtvButton, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "premiumDisburseId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "siteName",
						label: "Site",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "areaCode",
						label: "Hub Area",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "premiumStartDate",
						label: "Start Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premiumEndDate",
						label: "End Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "cvcAssociation",
						label: "CVC Association",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "totalPremiumAmount",
						label: "Premium Points",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "statusDesc",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "createdDate",
						label: "Date Submitted",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
				],
				filter: {
					areaCode: null,
					date: null,
					siteName: null,
					status: null,
				},
				icons: icons,
				is: {
					loading: false,
					showing: {
						filterModal: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				premiumPointActions: "AUTHDATA/premiumPointActions",
			}),
			...mapState("PREMIUMPOINT", ["premiumPointList", "totalRows"]),
			isAllowedToViewDetail() {
				return this.getActionPermission("detail")
			},
		},
		created() {
			this.getData()
		},
		beforeDestroy() {
			this.params = {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			}
		},
		methods: {
			...mapActions({
				getPremiumPointList: "PREMIUMPOINT/getPremiumPointList",
			}),
			closeFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: false,
				}
			},
			getActionPermission(name = null) {
				return findWhere(this.premiumPointActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPremiumPointList({
					...this.params,
				})
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			onFilter(filter) {
				this.filter = {
					...this.filter,
					...filter,
				}
				let searchParam = ""

				each(this.filter, (param, paramKey) => {
					if (param) {
						if (searchParam === "") {
							searchParam += `${paramKey}=${param}`
						} else {
							searchParam += `&${paramKey}=${param}`
						}
					}
				})

				this.updateParams({
					search: searchParam,
				})

				this.getData()
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: parseInt(currentPage, 10) })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				this.updateParams({ search: $event ? `id=${$event}` : null })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showDetail(premiumDisburseId) {
				this.$router.push({ name: "Premium Points Agri Input Progress Detail", params: { id: premiumDisburseId } })
			},
			showFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: true,
				}
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
